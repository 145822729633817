/*------------------------------------*\
	#FORMS
\*------------------------------------*/

.form {
	p {
		margin-bottom: $base-spacing-unit;
		&:last-child {
			margin: 0;
		}
		&.note {
			background-color: #e74c3c;
			color: #ffffff;
			font-weight: bold;
			padding: 10px;
		}
	}
	.form__label {
		display: block;
		font-weight: bold;
		margin-bottom: quarter($base-spacing-unit);
		&.form__label--inline {
			display: inline-block;
			font-weight: normal;
		}
	}
	.form__textfield,
	.form__textarea,
	.form__dropdown {
		border: none;
		border-radius: 2px;
		box-shadow: inset 0 1px 0 rgba(0, 0, 0, .05);
		outline: none;
		padding: halve($base-spacing-unit);
		width: 100%;
	}
	.form__checkbox {
		vertical-align: middle;
	}
	.form__multiple {
		display: block;
		margin-bottom: $base-spacing-unit;
	}
	.form__file {
		display: block;
		+ .form__file {
			margin-top: quarter($base-spacing-unit);
		}
	}
	.form__submit {
		background-color: $dark-blue;
		border: none;
		border-radius: 2px;
		color: #fff;
		font-weight: bold;
		outline: none;
		padding: halve($base-spacing-unit) $base-spacing-unit;
		position: relative;
		text-shadow: 0 1px 0 rgba(0, 0, 0, .05);
		transition: all .2s ease;
		width: 100%;
		&:active {
			top: 1px;
		}
		&:hover {
			background-color: $light-blue;
		}
	}
}
	/*
	 * Form on light background.
	 */
	.form--on-light {
		.form__textfield,
		.form__textarea,
		.form__dropdown {
			border: 1px solid #ccc;
		}
		.form__textfield,
		.form__textarea {
			&:focus {
				border-color: $light-blue;
			}
		}
	}

	/*
	 * Form on dark background.
	 */
	.form--on-dark {
		color: #333;
		margin-bottom: $base-spacing-unit;
		.form__label {
			color: #fff;
		}
		.form__dropdown,
		.form__submit {
			width: 50%;
		}
	}