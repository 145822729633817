/*------------------------------------*\
	#TABS
\*------------------------------------*/

.tab-panel {
	margin-bottom: double($base-spacing-unit);
}
.tab-nav {
	margin-bottom: double($base-spacing-unit);
	& > li {
		& > a {
			border-radius: 2px;
			display: block;
			@include font-size(16px);
			padding: halve($base-spacing-unit) $base-spacing-unit;
		}
		&:hover {
			a {
				background-color: $light-blue;
			}
		}
		&.active {
			& > a {
				background-color: $light-blue;
			}
		}
	}
}
.tab {
	display: none;
	&.active {
		display: block;
	}
}