/*------------------------------------*\
	#ALERTS
\*------------------------------------*/

.alert {
	border: 1px solid transparent;
	border-radius: 3px;
	color: #fff;
	margin-bottom: $base-spacing-unit;
	padding: quarter($base-spacing-unit) halve($base-spacing-unit);
	ul {
		@include bare-list;
	}
}
.alert--error {
	background-color: #e74c3c;
}
.alert--notice {
	background-color: #e67e22;
}
.alert--success {
	background-color: #2ecc71;
}