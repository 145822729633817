/*------------------------------------*\
	#CLEARFIX
\*------------------------------------*/

.clearfix,
%clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}