/*------------------------------------*\
	#CAROUSEL
\*------------------------------------*/

.carousel {
	position: relative;
}
.carousel-inner {
	height: 100%;
	position: relative;
	width: 100%;
	overflow: hidden;
}
.carousel-inner > .item {
	position: relative;
	display: none;
	height: 100%;
	overflow: hidden;
	-webkit-transition: .6s ease-in-out left;
	-o-transition: .6s ease-in-out left;
	transition: .6s ease-in-out left;
	width: 100%;
}
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
	line-height: 1;
}

@media all and (transform-3d), (-webkit-transform-3d) {
	.carousel-inner > .item {
		-webkit-transition: -webkit-transform .6s ease-in-out;
		-o-transition: -o-transform .6s ease-in-out;
		transition: transform .6s ease-in-out;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		-webkit-perspective: 1000;
		perspective: 1000;
	}
	.carousel-inner > .item.next,
	.carousel-inner > .item.active.right {
		left: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}
	.carousel-inner > .item.prev,
	.carousel-inner > .item.active.left {
		left: 0;
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
	.carousel-inner > .item.next.left,
	.carousel-inner > .item.prev.right,
	.carousel-inner > .item.active {
		left: 0;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	};
}
.carousel-inner > .active,
.carousel-inner > .next,
.carousel-inner > .prev {
	display: block;
}
.carousel-inner > .active {
	left: 0;
}
.carousel-inner > .next,
.carousel-inner > .prev {
	position: absolute;
	top: 0;
	width: 100%;
}
.carousel-inner > .next {
	left: 100%;
}
.carousel-inner > .prev {
	left: -100%;
}
.carousel-inner > .next.left,
.carousel-inner > .prev.right {
	left: 0;
}
.carousel-inner > .active.left {
	left: -100%;
}
.carousel-inner > .active.right {
	left: 100%;
}
.carousel-control {
	position: absolute;
	top: 50%;
	bottom: 0;
	left: 0;
	margin-top: -10px;
	width: 10%;
	@include font-size(20px, 1);
	color: #fff;
	text-align: center;
	filter: alpha(opacity=50);
	opacity: .5;
}
.carousel-control.left {

}
.carousel-control.right {
	right: 0;
	left: auto;
}
.carousel-control:hover,
.carousel-control:focus {
	color: #fff;
	text-decoration: none;
	filter: alpha(opacity=90);
	outline: 0;
	opacity: .9;
}
.carousel-control .icon-prev,
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right {
	position: absolute;
	top: 50%;
	z-index: 5;
	display: inline-block;
}
.carousel-control .icon-prev,
.carousel-control .glyphicon-chevron-left {
	left: 50%;
	margin-left: -10px;
}
.carousel-control .icon-next,
.carousel-control .glyphicon-chevron-right {
	right: 50%;
	margin-right: -10px;
}
.carousel-control .icon-prev,
.carousel-control .icon-next {
	width: 20px;
	height: 20px;
	margin-top: -10px;
	font-family: serif;
	line-height: 1;
}
.carousel-control .icon-prev:before {
	content: '\2039';
}
.carousel-control .icon-next:before {
	content: '\203a';
}
.carousel-indicators {
	position: absolute;
	bottom: 10px;
	left: 50%;
	z-index: 15;
	width: 60%;
	padding-left: 0;
	margin-left: -30%;
	text-align: center;
	list-style: none;
}
.carousel-indicators li {
	display: inline-block;
	width: 10px;
	height: 10px;
	margin: 1px;
	text-indent: -999px;
	cursor: pointer;
	background-color: #000 \9;
	background-color: rgba(0, 0, 0, 0);
	border: 1px solid #fff;
	border-radius: 10px;
}
.carousel-indicators .active {
	width: 12px;
	height: 12px;
	margin: 0;
	background-color: #fff;
}
.carousel-caption {
	position: absolute;
	right: 15%;
	bottom: 20px;
	left: 15%;
	z-index: 10;
	padding-top: 20px;
	padding-bottom: 20px;
	color: #fff;
	text-align: center;
	text-shadow: 0 1px 2px rgba(0, 0, 0, .6);
}
.carousel-caption .btn {
	text-shadow: none;
}

@media screen and (min-width: 768px) {
	.carousel-control .glyphicon-chevron-left,
	.carousel-control .glyphicon-chevron-right,
	.carousel-control .icon-prev,
	.carousel-control .icon-next {
		width: 30px;
		height: 30px;
		margin-top: -15px;
		font-size: 30px;
	}
	.carousel-control .glyphicon-chevron-left,
	.carousel-control .icon-prev {
		margin-left: -15px;
	}
	.carousel-control .glyphicon-chevron-right,
	.carousel-control .icon-next {
		margin-right: -15px;
	}
	.carousel-caption {
		right: 20%;
		left: 20%;
		padding-bottom: 30px;
	}
	.carousel-indicators {
		bottom: 20px;
	}
}