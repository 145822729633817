/*------------------------------------*\
	#AUTH BOX
\*------------------------------------*/

.auth-box {
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 0 10px rgba(0, 0, 0, .1);
	color: #333;
	margin: quadruple($base-spacing-unit) auto $base-spacing-unit;
	max-width: 400px;
	width: 95%;
}
	.auth-box__header {
		border-bottom: 1px solid #ddd;
		padding: $base-spacing-unit;
		.title {
			@include font-size(24px, 30px);
			margin: 0;
		}
	}
	.auth-box__form {
		padding: $base-spacing-unit;
	}
	.auth-box__link {
		margin: 0;
		margin-top: $base-spacing-unit;
		text-align: center;
		a {
			color: $dark-blue;
			&:hover {
				color: $light-blue;
			}
		}
		+ .auth-box__link {
			margin-top: 0;
		}
	}