/*------------------------------------*\
	#SESSION
\*------------------------------------*/

.session {
	color: lighten($light-blue, 10%);
	text-align: center;
	a {
		color: #fff;
		font-weight: bold;
		&:hover {
			text-decoration: underline;
		}
	}
}