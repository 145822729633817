/*------------------------------------*\
	#TITLE
\*------------------------------------*/

.page-title {
	border-bottom: 2px dotted lighten($light-blue, 10%);
	margin-bottom: double($base-spacing-unit);
	padding-bottom: $base-spacing-unit;
	position: relative;
	h1 {
		@include font-size(24px, 30px);
		font-weight: normal;
		margin-bottom: quarter($base-spacing-unit);
	}
	span {
		color: lighten($light-blue, 10%);
	}
	.filter {
		bottom: $base-spacing-unit;
		position: absolute;
		right: 0;
		select {
			border: none;
			border-radius: 2px;
			box-shadow: inset 0 1px 0 rgba(0, 0, 0, .05);
			color: #333;
			outline: none;
			padding: quarter($base-spacing-unit);
		}
	}
}