/*------------------------------------*\
	#COLORS
\*------------------------------------*/

// Base color settings
$base-text-color: #fff;
$base-background-color: #085499;

// Brand colors
$dark-blue: #085499;
$light-blue: #53c8e7;