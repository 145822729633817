/*------------------------------------*\
	#PAGINATION
\*------------------------------------*/

.pagination-container {
	float: left;
	text-align: center;
	width: 100%;
	@extend %clearfix;
}
	.pager {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			display: inline-block;
			@include font-size(24px);
			&:not(:last-child) {
				margin-right: halve($base-spacing-unit);
			}
			&.disabled {
				color: lighten($light-blue, 10%);
			}
		}
	}