/*------------------------------------*\
	#FUNCTIONS
\*------------------------------------*/

@function quarter($number) {
	@return round($number / 4);
}

@function halve($number) {
	@return round($number / 2);
}

@function double($number) {
	@return round($number * 2);
}

@function quadruple($number) {
	@return round($number * 4);
}