/*------------------------------------*\
	#LISTS
\*------------------------------------*/

// Remove extra vertical spacing when nesting lists.
li {
	> ul,
	> ol {
		margin-bottom: 0;
	}
}

// The list-bare object simply removes any indents and bullet points from lists.
.list-bare {
	list-style: none;
	margin:  0;
	padding: 0;
}

// The list-inline object simply displays a list of items in one line.
.list-inline {
	list-style: none;
	margin: 0;
	padding: 0;
	> li {
		display: inline-block;
	}
}

// Delimited list to semantically mark up lists of tags, etc.
.list-inline--delimited {
	> li + li {
		&:before {
			content: '\2014\00A0';
		}
	}
}