/*------------------------------------*\
	#MODAL
\*------------------------------------*/

.modal-backdrop {
	background-color: #000;
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1040;
	&.fade {
		opacity: 0;
	}
	&.in {
		opacity: .75;
	}
}
.modal-open {
	overflow: hidden;
	.modal {
		overflow-x: hidden;
		overflow-y: auto;
	}
}
.modal {
	bottom: 0;
	color: #333;
	display: none;
	left: 0;
	outline: 0;
	overflow: hidden;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1050;
	&.fade {
		.modal__dialog {
			transform: translate(0, -25%);
			transition: transform .2s ease-out;
		}
	}
	&.in {
		.modal__dialog {
			transform: translate(0, 0);
		}
	}
}
	.modal__dialog {
		margin: double($base-spacing-unit) auto;
		max-width: 600px;
		position: relative;
		width: 95%;
	}
		.modal__content {
			background-color: #fff;
			background-clip: padding-box;
			border-radius: 3px;
			box-shadow: 0 0 20px rgba(0, 0, 0, .1);
			outline: 0;
			position: relative;
		}
			.modal__header {
				border-bottom: 1px solid #ddd;
				padding: halve($base-spacing-unit) $base-spacing-unit;
			}
				.modal__title {
					font-family: $secondary-font;
					@include font-size(20px, 30px);
					margin: 0;
				}
			.modal__body {
				padding: $base-spacing-unit;
				position: relative;
			}
			.modal__footer {
				border-top: 1px solid #ddd;
				padding: halve($base-spacing-unit) $base-spacing-unit;
				text-align: right;
				.modal__button + .modal__button {
					margin-left: quarter($base-spacing-unit);
				}
			}
				.modal__button {
					border: none;
					border-radius: 3px;
					color: #fff;
					font-weight: bold;
					outline: none;
					margin: 0;
					padding: quarter($base-spacing-unit) $base-spacing-unit;
					position: relative;
					text-shadow: 0 1px 0 rgba(0, 0, 0, .05);
					text-transform: uppercase;
					transition: all .2s ease;
					&.modal__button--light-blue,
					&.modal__button--dark-blue {
						&:active {
							top: 1px;
						}
					}
					&.modal__button--light-blue {
						background-color: $light-blue;
						&:hover {
							background-color: darken($light-blue, 10%);
						}
					}
					&.modal__button--dark-blue {
						background-color: $dark-blue;
						&:hover {
						 	background-color: darken($dark-blue, 10%);
						}
					}
				}
.modal-scrollbar-measure {
	height: 50px;
	overflow: scroll;
	position: absolute;
	top: -9999px;
	width: 50px;
}