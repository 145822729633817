/*------------------------------------*\
	#RESPONSE
\*------------------------------------*/

.ajax-response {
	border-radius: 3px;
	bottom: $base-spacing-unit;
	color: #fff;
	display: none;
	padding: $base-spacing-unit;
	position: fixed;
	right: $base-spacing-unit;
	&.loading {
		background-color: $dark-blue;
	}
	&.success {
		background-color: #2ecc71;
	}
	&.error {
		background-color: #e74c3c;
	}
}