/*------------------------------------*\
	#CONTACT
\*------------------------------------*/

.contact {
	border-top: 2px dotted lighten($light-blue, 10%);
	color: lighten($light-blue, 10%);
	@include font-size(12px);
	margin-bottom: halve($base-spacing-unit);
	margin-top: $base-spacing-unit;
	padding-top: $base-spacing-unit;
	text-align: center;
	span {
		display: block;
		a {
			text-decoration: underline;
			&:hover {
				text-decoration: none;
			}
		}
	}
}