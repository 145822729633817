/*------------------------------------*\
	#PHOTO
\*------------------------------------*/

.photo-container {
	+ .photo-container {
		margin-top: double($base-spacing-unit);
	}
}
	.photo {
		margin-bottom: $base-spacing-unit + halve($base-spacing-unit);
		position: relative;
		img {
			border-radius: 3px;
			display: block;
			height: auto;
			width: 100%;
		}
	}
		.photo__meta {
			@include font-size(16px, 20px);
			font-weight: bold;
			text-shadow: 0 1px 0 rgba(0, 0, 0, .05);
			.author {
				bottom: halve($base-spacing-unit);
				left: halve($base-spacing-unit);
				position: absolute;

			}
			.vote {
				bottom: halve($base-spacing-unit);
				@include font-size(18px);
				position: absolute;
				right: halve($base-spacing-unit);
				&.voted {
					color: #e74c3c;
				}
				&.not-voted, &.not-voted--final {
					cursor: pointer;
				}
			}
		}