/*------------------------------------*\
	#HEADER
\*------------------------------------*/

.header {
	padding: $base-spacing-unit 0 double($base-spacing-unit);
	@extend %clearfix;
}
	.logo {
		display: inline-block;
		float: left;
		img {
			display: block;
			height: 125px;
			width: auto;
		}
	}
	.navigation {
		display: inline-block;
		float: right;
		margin: $base-spacing-unit 0;
		text-shadow: 0 1px 0 rgba(0, 0, 0, .05);
		ul {
			li {
				font-family: $secondary-font;
				@include font-size(14px);
				&:hover, &.active {
					a {
						background-color: #fff;
						border-radius: 3px;
						color: $dark-blue;
					}
				}
				&.upload {
					a {
						border: 2px solid #fff;
					}
				}
				a {
					border: 2px solid transparent;
					border-radius: 3px;
					color: #fff;
					display: inline-block;
					@include font-size(14px, 20px);
					outline: none;
					padding: halve($base-spacing-unit) - 2px $base-spacing-unit;
					text-transform: uppercase;
				}
			}
		}
	}