/*------------------------------------*\
	#MIXINS
\*------------------------------------*/

// Make list bare.
@mixin bare-list {
	list-style: none;
	margin: 0;
	padding: 0;
}

// Create a fully formed type style (sizing and vertical rhythm) by passing in a single value.
@mixin font-size($font-size, $line-height: auto) {
	font-size: $font-size;
	font-size: ($font-size / $base-font-size) * 1rem;

	@if $line-height == auto {
		line-height: ceil($font-size / $base-line-height) * ($base-line-height / $font-size);
	}
	@else {
		@if (type-of($line-height) == number or $line-height == inherit or $line-height == normal) {
			line-height: $line-height;
		}
		@elseif ($line-height != none and $line-height != false) {
			@warn "D'oh! ‘#{$line-height}’ is not a valid value for `line-height`."
		}
	}
}

// Disable text overflow.
@mixin text-overflow {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}