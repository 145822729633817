/*------------------------------------*\
	#PAGE
\*------------------------------------*/

// Base page-level styling.
html {
	background: linear-gradient(90deg, $dark-blue 10%, $light-blue 90%);
	background-color: $base-background-color;
	color: $base-text-color;
	font-family: $primary-font;
	font-size: ($base-font-size / 16px) * 1em;
	line-height: $base-line-height / $base-font-size;
	min-height: 100%;
}